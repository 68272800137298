<!-- eslint-disable -->
<template>
  <app-module-view :disable-breadcrumb="true">
    <template slot="body">
      <h2 class="gemius-info">
        <span>
          {{ `1.9.2023 - Ukončené napojenie NMH KPI na externý zdroj dát (IAB Gemius). Pre aktuálne dáta využite - ` }}
        </span>
        <span>
          <router-link :to="{name: 'kpi_nmh'}" >
            {{ `NMH KPI` }}
          </router-link>
        </span>
      </h2>
      <app-kpi-nmh-filter></app-kpi-nmh-filter>
      <div class="card">
        <div class="card-body">
          <button
            v-for="(group, key) in groupLabels" :key="`filter_${key}`"
            v-if="key !== 'nmhVideo'"
            class="btn btn-secondary m-b-5 m-r-5"
            :class="{active: groupFilter.includes(key)}"
            @click.passive="toggleGroupFilter(key, $event)"
          >
            {{ group }}
          </button>
          <button v-if="groupFilter.length" class="btn btn-success fa-pull-right" @click="reset">
            {{ $t('buttons.reset_filter') }}
          </button>
        </div>
      </div>
      <template v-for="(group, key) in groupLabels" v-if="key !== 'nmhVideo'">
        <div
          v-if="settingsList.length > 0 && (groupFilter.length === 0 || groupFilter.includes(key))"
          :key="`group_${key}`"
          class="card"
        >
          <div class="card-body">
            <h4 class="text-themecolor">{{ group }}</h4>
            <div class="overflow">
              <table class="table table-striped">
                <template v-for="(stats, groupType) in settingsList[0][key]" v-if="shouldDisplay(groupType, key)">
                  <thead :key="`group_${key}_${groupType}_head`">
                  <tr>
                    <th width="200">{{ $t('kpiNmh.' + groupType) }}</th>
                    <th v-for="({id}) in settingsList" :key="`${key}_${groupType}_${id}`">{{ formatDate(id) }}</th>
                  </tr>
                  </thead>
                  <tbody :key="`group_${key}_${groupType}_body`">
                  <tr>
                    <th>{{ groupType === 'realUsers' ? 'Real' : 'Forecast / real' }}</th>
                    <td v-for="setting in settingsList" :key="`${key}_${setting.id}_real`">
                      {{ getRealStats(key, groupType, setting.id) | formatNumber(0, 0) }}
                    </td>
                  </tr>
                  <tr v-if="groupType === 'pageViews'">
                    <th>PV / Visits</th>
                    <td v-for="setting in settingsList" :key="`${key}_${setting.id}_pv_visits`">
                      {{ getRealStats(key, 'pvVisits', setting.id) | formatNumber }}
                    </td>
                  </tr>
                  <tr v-if="groupType === 'realUsers'">
                    <th>Daily average</th>
                    <td v-for="setting in settingsList" :key="`${key}_${setting.id}_avg_real`">
                      {{ getRealStats(key, 'avgRealUsers', setting.id) | formatNumber(0, 0) }}
                    </td>
                  </tr>
                  <tr v-if="displayBudget(key)">
                    <th>Budget KPI's</th>
                    <td v-for="setting in settingsList" :key="`${key}_${setting.id}_budget`">
                      {{ setting[key][groupType].budget | formatNumber }}
                    </td>
                  </tr>
                  <tr v-if="displayAmb(key)">
                    <th>NMH amb. KPI's</th>
                    <td v-for="setting in settingsList" :key="`${key}_${setting.id}_nmhamb`">
                      {{ setting[key][groupType].nmhAmb | formatNumber }}
                    </td>
                  </tr>
                  <tr v-if="displayPercentRealKpi(key)">
                    <th>% {{ $t('kpiNmh.realityVsKpi') }}</th>
                    <td
                      v-for="setting in settingsList" :key="`${key}_${setting.id}_ratio`"
                      class="text-danger"
                      :class="{
                                                    'text-warning': getRatio(key, groupType, setting) >= 70,
                                                    'text-green': getRatio(key, groupType, setting) >= 100,
                                                }"
                    >
                      {{ getRatio(key, groupType, setting) | formatNumber(0, 0) }} %
                    </td>
                  </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </div>
        </div>
      </template>
    </template>
  </app-module-view>
</template>

<script>
import moment from 'moment'
import ModuleView from '../ModuleView'
import KpiNmhFilter from './KpiNmhFilterOld'
import { GROUP_BRAND_MAP, GROUP_LABEL } from '../../model/ValueObject/kpiNmhGroups'
import { ROLE_DEPARTMENT_MANAGER, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const EXCLUDED_GROUPS = ['pluskaHp', 'pluskaUmbrella']

export default {
  /* eslint-disable */
  name: 'KpiNmhOld',
  data () {
    return {
      groupFilter: []
    }
  },
  methods: {
    displayPercentRealKpi (group) {
      return EXCLUDED_GROUPS.includes(group) === false
    },
    displayBudget (group) {
      return this.isSuperAdmin && EXCLUDED_GROUPS.includes(group) === false
    },
    displayAmb (group) {
      return EXCLUDED_GROUPS.includes(group) === false
    },
    formatDate (date) {
      return moment(date).format('M/Y')
    },
    getRealStats (group, groupType, month) {
      let stats = this.list
      let monthAvg = 1
      let actualMonthMultiplier = 1
      if (this.currentMonth === month) {
        const lastDay = Math.max(...this.month.map(({ date }) => new Date(date).getDate()))
        monthAvg = this.monthDays / lastDay
        actualMonthMultiplier = 1 / lastDay
        stats = this.month
        if (groupType === 'realUsers') {
          monthAvg = 0
        }
      }
      stats = stats.filter(({
                              site,
                              date
                            }) => date.indexOf(month) === 0 && GROUP_BRAND_MAP[GROUP_LABEL[group]].includes(site))
      let metric
      switch (groupType) {
        case 'realUsers':
          metric = 'real_users'
          break
        case 'videoViews':
          metric = 'video_views'
          break
        case 'avgRealUsers':
          metric = this.currentMonth === month ? 'real_users' : 'avg_real_users'
          return stats.reduce((sum, item) => (sum + (item.metric[metric] || 0)), 0) * actualMonthMultiplier
        case 'pvVisits':
          return (stats.reduce((sum, item) => (sum + (item.metric.page_views || 0)), 0) * monthAvg) /
            (stats.reduce((sum, item) => (sum + (item.metric.visits || 0)), 0) * monthAvg) || 0
        default:
          metric = 'page_views'
      }
      return stats.reduce((sum, item) => (sum + (item.metric[metric] || 0)), 0) * monthAvg
    },
    getRatio (group, groupType, setting) {
      return this.getRealStats(group, groupType, setting.id) / (setting[group][groupType].nmhAmb || 1) * 100
    },
    reset () {
      this.groupFilter = []
    },
    toggleGroupFilter (key, event) {
      event.target.blur()
      if (this.groupFilter.includes(key)) {
        this.groupFilter = this.groupFilter.filter((k) => k !== key)
      } else {
        this.groupFilter.push(key)
      }
    },
    shouldDisplay (groupType, key) {
      return (groupType === 'videoViews' || groupType === 'realUsers' && key === 'nmhContent') === false
    }
  },
  computed: {
    groupLabels () {
      return GROUP_LABEL
    },
    isSuperAdmin () {
      return this.$store.getters['user/hasRole'](ROLE_SUPER_ADMIN) || this.$store.getters['user/hasRole'](ROLE_DEPARTMENT_MANAGER)
    },
    list () {
      return this.$store.getters['kpiNmh/list']
    },
    month () {
      return this.$store.getters['kpiNmh/month']
    },
    currentMonth () {
      return moment().format('Y-MM')
    },
    monthDays () {
      return parseInt(moment().endOf('month').format('D'))
    },
    settingsList () {
      return this.$store.getters['kpiNmh/settingsList']
    }
  },
  components: {
    appModuleView: ModuleView,
    appKpiNmhFilter: KpiNmhFilter
  }
}
</script>

<style lang="scss" scoped>
.text-green {
  color: #3CB043 !important;
}

.overflow {
  overflow: auto;
}

table tr {
  background-color: #fff;
}

table th:first-child {
  white-space: nowrap;
  background-color: inherit;
  position: sticky;
  left: 0;
}

table thead:nth-of-type(n+2) th {
  padding-top: 30px;
}

.gemius-info {
  color: red;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>
